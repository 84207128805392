import React, { useEffect } from 'react';
// import logo from './logo.svg';
import './App.css';

function App() {
  useEffect(() => {
    // document.body.style.background = "black";
    const canvas = document.getElementById('Matrix');
    const context = canvas.getContext('2d');

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const katakana = 'アァカサタナハマヤャラワガザダバパイィキシチニヒミリヰギジヂビピウゥクスツヌフムユュルグズブヅプエェケセテネヘメレヱゲゼデベペオォコソトノホモヨョロヲゴゾドボポヴッン';
    const latin = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const nums = '0123456789';

    const alphabet = katakana + latin + nums;

    const fontSize = 16;
    const columns = canvas.width / fontSize;

    const rainDrops = [];

    for (let x = 0; x < columns; x++) {
      rainDrops[x] = 1;
    }

    const draw = () => {
      context.fillStyle = 'rgba(0, 0, 0, 0.05)';
      context.fillRect(0, 0, canvas.width, canvas.height);

      context.fillStyle = '#0F0';
      context.font = fontSize + 'px monospace';

      for (let i = 0; i < rainDrops.length; i++) {
        const text = alphabet.charAt(Math.floor(Math.random() * alphabet.length));
        context.fillText(text, i * fontSize, rainDrops[i] * fontSize);

        if (rainDrops[i] * fontSize > canvas.height && Math.random() > 0.975) {
          rainDrops[i] = 0;
        }
        rainDrops[i]++;
      }
    };

    setInterval(draw, 30);
  });

  return (
    <div className="App">
      <header className="App-header">
        <p id="temp-text">
          Coming Soon ... visit my other site <a class="cello-journeys-link" href="https://www.cellojourneys.com">www.cellojourneys.com</a>
          {/* <div class="container mx-auto px-4 bg-gray-50">
            USING TAILWIND IN THIS CONTAINER
          </div> */}
        </p>
        <canvas id="Matrix"></canvas>
      </header>
    </div>
  );
}

export default App;
